import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "title"];
var __jsx = React.createElement;
import { Box, Heading } from '@playful/design_system';
import React from 'react';
import { useProjectTemplates } from './ProjectTemplates';
export function ProjectTemplatesContent(_ref) {
  var children = _ref.children,
    title = _ref.title,
    boxProps = _objectWithoutProperties(_ref, _excluded);
  var _useProjectTemplates = useProjectTemplates(),
    shadowRef = _useProjectTemplates.shadowRef;
  return __jsx(Box, boxProps, title && __jsx(Heading, {
    as: "h1",
    size: "lg",
    noOfLines: 1
  }, title), __jsx(Box, {
    display: "flex",
    flexDir: "column",
    gap: 10,
    py: 4
  }, children), __jsx("div", {
    style: {
      marginTop: 0
    },
    ref: shadowRef
  }));
}